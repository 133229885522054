import axios from "axios";

const urlBase = `${process.env.REACT_APP_URL_BASE}/api-admin-files`;
//const urlBase = `http://localhost:3000`

function postUploadApp(data) {
  var config = {
    method: "post",
    url: `${urlBase}/uploadapp`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { postUploadApp };