import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";

//component
import BodyCard from "../../components/bodyCard";

export default function Avipam() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      setUser({
        idEvent: idEvent,
        idPart: idPart,
      });
    }
  }, []);

  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          type={"Upload de Vídeo"}
          fileName="appUpload"
          title={""}
          description={[{ data: "" }]}
          id={""}
          button={"Enviar"}
        />
      </div>
    </div>
  );
}
