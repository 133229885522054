import React, { useState } from "react";
import { Row, Col, Label } from 'reactstrap'
import { postUploadApp } from "../services";
import SweetAlert from "react-bootstrap-sweetalert";
import "./styled.css";

export default function BodyCard(props) {
  const [file, setFile] = useState(null)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [text, setText] = useState('')
  const [uploadAppSuccess, setUploadAppSuccess] = useState(false)
  const [uploadAppExists, setUploadAppExists] = useState(false)
  const [loginEventInvalid, setLoginEventInvalid] = useState(false)
  const [errorFileSize, setErrorFileSize] = useState(false)


  async function send() {
    if (props?.user?.idEvent && props?.user?.idPart) {

      var data = new FormData();
      if (file !== "" && file?.target?.files[0]) {
        if (bytesToMegaBytes(file?.target?.files[0].size) > 50) {
          setErrorFileSize(true)
          return
        }
        data.append("files", file.target.files[0]);
      }
      data.append("folder", "video")
      data.append("eventId", props?.user?.idEvent);
      data.append("loginId", props?.user?.idPart);

      await postUploadApp(data).then((response) => {
        if (response.message === 'created') {
          setUploadAppSuccess(true)
          setErrorFileSize(false)
        }
        if (response.message === 'FILE_YET_UPLOADED') {
          setUploadAppExists(true)
        }
        if (response.message === 'LOGIN_NOT_FOUND' || response.message === 'EVENT_NOT_FOUND') {
          setLoginEventInvalid(true)
        }
      })
        .catch((error) => {
          console.log("erro", error);
        });
    }
  }
  const bytesToMegaBytes = bytes => (bytes / (1024 * 1024)).toFixed(2);
  return (
    <>
      {uploadAppSuccess && (
        <SweetAlert
          success
          title="Obrigado!"
          onConfirm={() => {
            setUploadAppSuccess(false);
          }}
        >
          Upload realizado com sucesso!
        </SweetAlert>
      )}
      {uploadAppExists && (
        <SweetAlert
          warning
          title="Parabéns!"
          onConfirm={() => {
            setUploadAppExists(false);
          }}
        >
          Parabéns! Você já realizou o upload do seu vídeo.
        </SweetAlert>
      )}
      {loginEventInvalid && (
        <SweetAlert
          warning
          title="Ops! Algo deu errado"
          onConfirm={() => {
            setLoginEventInvalid(false);
          }}
        >
          Usuário ou evento inválido.
        </SweetAlert>
      )}

      <div className="card-files">
        <div className="items-file">
          <div className="header-item">
            <h1>{props.type}</h1>
            <h4>{fileUploaded ? 'Parabéns! Você já realizou o upload do seu vídeo.' : props?.title}</h4>
            {props.description.map((desc) => (
              <p className="subtitle-files">{desc.data}</p>
            ))}
          </div>
          <div className="list-items">
            <Row style={{ marginBottom: 20 }}>
              <Col sm={12}>
                <div>
                  <Label htmlFor="formFile" className="form-label">
                    Selecione o Vídeo
                  </Label>
                  <div id="upload_button">
                    <label>
                      <input type="file" accept="video/*" onChange={(e) => {
                        if (e.target.value !== '') {
                          setText(e.target.value.replace(/.*[\/\\]/, ''))
                          setErrorFileSize(false)
                          setFile(e);
                        }
                      }} />
                      <input type="text" value={text} disabled />
                      <span className="btn-choosen">...</span>
                    </label>
                  </div>
                  {errorFileSize && <p style={{ color: "red" }}>O Tamanho excedeu 50Mb.</p>}
                </div>
              </Col>
            </Row>
          </div>
          <div disabled={fileUploaded} className={"btn-download"} onClick={() => {
            if (!fileUploaded) {
              send()
            }
          }} >
            {props.button}
          </div>
        </div>
      </div>
    </>
  );
}