//styles
import "bootstrap/dist/css/bootstrap.css";
//rotas
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Avipam from "./pages/avipam";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/avipam" element={<Avipam />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;